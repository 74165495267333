import React, { useState } from 'react';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../redux/store';
import api from '../../http';
import { toast } from 'react-hot-toast';
import placeholderImage from '../../assets/images/supersite.svg';
import Toast from '../../utils/toast';
import { getProjects } from '../../redux/slice/project-slice';

interface IsLoadingState {
  category: boolean;
  element: boolean;
  workItem: boolean;
}

const CsvPage = () => {


  const { id } = useParams<{ id: string }>();

  const [isLoading, setIsLoading] = useState<IsLoadingState>({
    category: false,
    element: false,
    workItem: false,
  });

  const [selectedFiles, setSelectedFiles] = useState({
    category: null,
    element: null,
    workItem: null,
  });

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>, fileType: string) => {
    const file = event.target.files?.[0];
    setSelectedFiles((prevState) => ({ ...prevState, [fileType]: file }));
  };

  const handleFileUpload = async (file: File, fileType: string) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('projectId', id!.toString());

    try {
      setIsLoading((prevState) => ({ ...prevState, [fileType]: true }));
      const response = await api.post(`/${fileType}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(`Uploaded ${fileType} file:`, response.data);
      Toast.showSuccessToast(response.data.message);
    } catch (error: any) {
      Toast.showErrorToast(error.toString());
      console.error('File upload error:', error);
    } finally {
      setIsLoading((prevState) => ({ ...prevState, [fileType]: false }));
    }
  };

  const handleFormSubmit = (event: React.FormEvent, fileType: string) => {
    event.preventDefault();
    //@ts-ignore
    const file = selectedFiles[fileType];
    if (file) {
      handleFileUpload(file, fileType);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card>
          <CardContent>
            <ProjectDetails />
          </CardContent>
        </Card>
      </Grid>
      {Object.entries(selectedFiles).map(([fileType, file]) => (
        <Grid item key={fileType} xs={12} sm={6} md={4} lg={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">{fileType}</Typography>
              <form onSubmit={(event) => handleFormSubmit(event, fileType)}>
                <input
                  type="file"
                  accept=".csv"
                  onChange={(event) => handleFileSelect(event, fileType)}
                  style={{ marginBottom: '1rem' }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isLoading[fileType as keyof IsLoadingState] || !file}
                  fullWidth
                >
                  {isLoading[fileType as keyof IsLoadingState]
                    ? 'Processing...'
                    : `Add ${fileType}`}
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default CsvPage;

const ProjectDetails = () => {

  const dispatch = useDispatch();

  const { id } = useParams<{ id: string }>();
  const project = useSelector((state: RootState) => {
    console.log(state.project.projects);
    console.log(state.project.projects.find((p) => p.id === id));
    return state.project.projects.find((p) => p.id === id);
  });

  if (!project) {
    dispatch(getProjects())
    return <div>Loading project details...</div>;
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ height: 100, width: 100, marginRight: '1rem' }}>
        <img
          src={project.photos ? project.photos[0] : placeholderImage}
          alt={project.name}
          style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
          }}
          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
            const target = e.target as HTMLImageElement;
            target.onerror = null;
            target.src = placeholderImage;
          }}
        />
      </div>
      <h2>{project.name}</h2>
    </div>
  );
};
