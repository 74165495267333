import {useEffect} from "react";
import {sounds} from "./constants";

class Sound {

    static playSuccess = () => {
        const audio = new Audio(sounds.SUCCESS);
        audio.play();
    }
    static playError = () => {
        const audio = new Audio(sounds.ERROR);
        audio.play();
    }

}

export default Sound