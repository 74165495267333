import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const errorState = {
    email: '',
    password: '',
}

const initialState = {
    isAuth: false,
    isLoading: false,
    account : {},
    token : '',
    errors: { ...errorState }
}


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        getLogin: (state, action: { type: string, payload: { email: string, password: string } }) => {
            state.isLoading = true;
            state.errors = { email: '', password: '' }
        },
        getLogout: (state, action: { type: string, payload:any}) => {
            localStorage.clear();
            state.isAuth = false;
            state.token = ''
            window.location.reload()
        },
        setLogin: (state, action) => {
            state.isLoading = false;
            state.isAuth = true;
            state.account = action.payload.account;
            state.token = action.payload.jwt;
            localStorage.setItem('token',action.payload.jwt);
            window.location.reload();
        },
        failedLogin: (state, action?: PayloadAction<any>) => {
            state.isLoading = false;
            state.errors = action?.payload
        }
    }
});

export const { getLogin, setLogin, failedLogin,getLogout } = authSlice.actions;
export default authSlice.reducer;

