import { Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from '../page/auth/login';
import AppLayout from '../components/layout/app-layout';
import CsvPage from '../page/csv/csv-page';
import ProjectPage from '../page/project/project-page';

export const ROUTE_LOGIN = '/login';
export const ROUTE_DASHBOARD = '/dashboard';
export const ROUTE_HOME = '/';
export const ROUTE_PROJECT_CSV = '/project/:id/csv'; 

const MyRoute = () => {
  const token = localStorage.getItem('token');
  return (
    <Routes>
      <Route
        path={ROUTE_LOGIN}
        element={!token ? <LoginPage /> : <Navigate to={ROUTE_HOME} />}
      />
      <Route
        path="/"
        element={token ? <AppLayout /> : <Navigate to={ROUTE_LOGIN} />}
      >
        <Route path={ROUTE_HOME} element={<ProjectPage />} />
        <Route path={ROUTE_PROJECT_CSV} element={<CsvPage />} />
      </Route>
    </Routes>
  );
};

export default MyRoute;
