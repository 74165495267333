import { takeLatest, put, call } from 'redux-saga/effects';
import { apiLogin } from '../../http';
import { ResponseDefault } from "../../interfaces/interface-response";
import { failedLogin, setLogin } from "../slice/auth-slice";
import { ResponseLogin } from "../../response/response-login";


function* workLogin({ payload }: any): Generator<object, any, object> {
    try {
        console.log(payload);
        const res: ResponseDefault = yield call(apiLogin, payload);
        const response: ResponseLogin = res.data;
        if (res.status === 200) {
            if (response.success) {
                yield put(setLogin(
                    response.data
                ));
            } else {
                yield put(failedLogin(response.error))
            }
        } else {
            yield put(failedLogin(response.error))
        }
    }
    catch (e) {
        console.log(e)
        yield put(failedLogin)
    }
}


export function* watchLogin() {
    yield takeLatest('auth/getLogin', workLogin);
}
