import toast from "react-hot-toast";
import sound from "./sound";

class Toast{

    static showSuccessToast(message:string){
        sound.playSuccess();
        toast.success(message)
    }

    static showErrorToast(message:string){
        sound.playError();
        toast.error(message)
    }

}

export default  Toast