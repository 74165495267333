import { takeLatest, put, call } from 'redux-saga/effects';
import { apiProjects } from '../../http';
import { failedProjects, setProjects } from '../slice/project-slice';
import { ResponseDefault } from '../../interfaces/interface-response';
import { ResponseProjects } from '../../response/response-projects';

function* workProjects(): Generator<object, any, object> {
  console.log('workProjectsworkProjectsworkProjects');
  try {
    const res: ResponseDefault = yield call(apiProjects);
    const response: ResponseProjects = res.data;
    if (res.status === 200) {
      if (response.success) {
        yield put(setProjects(response.data || []));
      } else {
        yield put(failedProjects(response.error));
      }
    } else {
      yield put(failedProjects(response.error));
    }
  } catch (e:any) {
    console.log(e);
    yield put(failedProjects(e));
  }
}

export function* watchProjects() {
  console.log('watchProjects');
  yield takeLatest('project/getProjects', workProjects);
}
