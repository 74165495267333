import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Project } from "../../interfaces/interface-project";

interface ProjectState {
  isProjectsLoading: boolean;
  projects: Project[];
}

const initialState: ProjectState = {
  isProjectsLoading: false,
  projects: [],
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    getProjects: (state) => {
      state.isProjectsLoading = true;
    },
    setProjects: (state, action: PayloadAction<Project[]>) => {
      state.isProjectsLoading = false;
      state.projects = action.payload;
    },
    failedProjects: (state,action?: PayloadAction<any>) => {
      state.isProjectsLoading = false;
    },
  },
});

export const { getProjects, setProjects, failedProjects } = projectSlice.actions;
export default projectSlice.reducer;
