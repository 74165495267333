const isSecure = window.location.protocol === 'https:';
const protocol = isSecure ? 'https://' : 'http://';

const localhost = {
    APP_ENV: 'Localhost',
    APP_NAME: 'Supersite',
    APP_URL: `${protocol}admin.supersite.app`,
    API_URL: `${protocol}api.admin.supersite.app`,
    BACKEND_IMAGE_URL: `${protocol}api.admin.supersite.app`,
    BACKEND_URL: `${protocol}api.admin.supersite.app`
};

// const development = {
//     APP_ENV: 'Development',
//     APP_NAME: 'Supersite',
//     APP_URL: `${protocol}admin.supersite.app`,
//     API_URL: `${protocol}localhost:5000`,
//     BACKEND_IMAGE_URL: `${protocol}api.admin.supersite.app`,
//     BACKEND_URL: `${protocol}api.admin.supersite.app`
// };

const development = {
    APP_NAME: 'Supersite',
    APP_ENV: 'Production',
    APP_URL: `${protocol}admin.supersite.app`,
    API_URL: `${protocol}api.admin.supersite.app`,
    BACKEND_IMAGE_URL: `${protocol}api.admin.supersite.app`,
    BACKEND_URL: `${protocol}api.admin.supersite.app`
};

const production = {
    APP_NAME: 'Supersite',
    APP_ENV: 'Production',
    APP_URL: `${protocol}admin.supersite.app`,
    API_URL: `${protocol}api.admin.supersite.app`,
    BACKEND_IMAGE_URL: `${protocol}api.admin.supersite.app`,
    BACKEND_URL: `${protocol}api.admin.supersite.app`
};

const env = process.env.REACT_APP_ENV || 'production';

const environments: {
    [key: string]: {
        APP_ENV: string;
        APP_NAME: string;
        APP_URL: string;
        API_URL: string;
        BACKEND_IMAGE_URL: string;
        BACKEND_URL: string;
    };
} = {
    localhost,
    development,
    production
};

console.log(env)

export default environments[env];
