import axios from 'axios';
import config from "../config/config";
import { ENDPOINT_LOGIN, ENDPOINT_PROJECTS } from "./end-points";

const BASE_URL: string = config.API_URL;


const api = axios.create({
  baseURL: BASE_URL,
  timeout: 0,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('token')}`
  },
  cancelToken: new axios.CancelToken((cancel) => {
    console.log("cancel====>", cancel)
  }),
});


export const apiLogin: any = async (data: any) => await api.post(ENDPOINT_LOGIN, data);

export const apiProjects: any = async () => await api.get(ENDPOINT_PROJECTS);

axios.interceptors.request.use((request: any): any => {
  console.log('request interceptor', request);
}, (err) => {
  console.log('error is', err);
  return Promise.reject(err);
});


export default api;