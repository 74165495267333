import { Card, CardContent, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProjects } from '../../redux/slice/project-slice';
import { RootState } from '../../redux/store';
import placeholderImage from '../../assets/images/supersite.svg';
import Skeleton from 'react-loading-skeleton';
import { Link, useNavigate } from 'react-router-dom';

const ProjectPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projects, isProjectsLoading } = useSelector(
    (state: RootState) => state.project
  );

  useEffect(() => {
    dispatch(getProjects());
  }, [dispatch]);

  const handleComponentClick = (projectId:string) => {
    navigate(`/project/${projectId}/csv`);
  };

  return (
    <Grid container spacing={2}>
      {!isProjectsLoading ? (
        projects.map((project, index) => (
          <Grid item key={index} xs={12} sm={6} md={3} lg={2}>
            <Card
              onClick={() => handleComponentClick(project.id)}
              style={{ cursor: 'pointer' }}
            >
              <div style={{ height: 150 }}>
                <img
                  src={project.photos ? project.photos[0] : placeholderImage}
                  alt={project.name}
                  style={{
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                  }}
                  onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                    const target = e.target as HTMLImageElement;
                    target.onerror = null;
                    target.src = placeholderImage;
                  }}
                />
              </div>
              <div style={{ height: 30 }}>
                <Typography
                  style={{
                    padding: '8px 16px',
                  }}
                >
                  {project.name}
                </Typography>
              </div>
              <CardContent>
                {/* Additional card content if needed */}
              </CardContent>
            </Card>
          </Grid>
        ))
      ) : (
        <Skeleton height={150} />
      )}
    </Grid>
  );
};

export default ProjectPage;
